import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiPickersModal: {
      dialogRoot: {
        padding: "0px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "460px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    paperWidthSm: {
      maxWidth: "600px",
      padding: "20px 30px",
    },
    MuiCollapse: {
      wrapperInner: {
        paddingLeft: "44px",
      },
    },

    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",
        // color: "rgb(255, 125, 104) !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
      },
    },
    MuiPickerDTToolbar: { toolbar: { borderRadius: "8px 8px 0px 0px" } },

    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "5px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "50px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlinedSizeLarge: {
        padding: "7px 35px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  // {
  //   name: "LIGHT",
  //   overrides: {
  //     MuiMenu: {
  //       list: {
  //         outline: "0",
  //         background: "#ffffff",
  //       },
  //     },
  //     MuiDialog: {
  //       paper: {
  //         margin: "32px",
  //         background: "#ffffff",
  //         padding: "20px 30px",
  //         position: "relative",
  //         overflowY: "auto",
  //         color: "#fff !important",
  //         borderRadius: "10px !important",
  //         "@media(max-width:767px)": {
  //           padding: "10px",
  //         },
  //       },
  //     },
  //     MuiSwitch: {
  //       switchBase: {
  //         color: "#FF6600 !important",
  //       },
  //       track: {
  //         backgroundColor: "#a8a4a4",
  //         opacity: "1",
  //       },
  //     },
  //     MuiInputBase: {
  //       root: {
  //         color: "#000",
  //         height: "50px",

  //         background: "rgba(0, 0, 0, 0.04)",
  //         borderRadius: "10px !important",
  //         // height: "50px !important",
  //       },
  //     },
  //     MuiSelect: {
  //       icon: {
  //         color: "#000",
  //       },
  //     },
  //     MuiTableHead: {
  //       root: {
  //         background: "transparent",
  //         borderTop: "1px solid #b3aaaa",
  //       },
  //     },
  //     MuiTableBody: {
  //       root: {
  //         background:
  //           "linear-gradient(152.97deg, rgb(255 255 255 / 65%) 0%, rgb(62 60 60 / 27%) 100%)",
  //       },
  //     },
  //     MuiTableRow: {
  //       root: {
  //         borderBottom: "1px solid #b3aaaa",
  //         "&:hover": {
  //           backgroundColor: "#00000017",
  //         },
  //         "&:last-child": {
  //           borderBottom: "none",
  //         },
  //       },
  //     },
  //     MuiTableCell: {
  //       head: {
  //         padding: "8px 16px",
  //         fontWeight: "400",
  //       },
  //     },

  //     MuiButton: {
  //       containedPrimary: {
  //         background:
  //           "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
  //         borderRadius: "10px",
  //         color: "#fff",
  //         fontSize: "14px",
  //         fontWeight: "600",
  //         height: "45px",
  //         lineHeight: " 21px",
  //         padding: "10px 39px",
  //         marginRight: "10px",
  //         "&:hover": {
  //           color: "#000",
  //           background: "transparent",
  //           boxShadow:
  //             "0 1px 0 0 #ad5165, 0 -1px 0 0 #7e46a1, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
  //           backgroundColor: "transparent",
  //         },
  //       },

  //       containedSecondary: {
  //         borderRadius: "10px",
  //         color: "#000",
  //         fontSize: "14px",
  //         fontWeight: "600",
  //         height: "45px",
  //         lineHeight: " 21px",
  //         padding: "10px 39px",
  //         marginRight: "10px",

  //         background: "transparent",
  //         boxShadow:
  //           "0 1px 0 0 #ad5165, 0 -1px 0 0 #7e46a1, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
  //         backgroundColor: "transparent",
  //         "&:hover": {
  //           color: "#fff",
  //           background:
  //             "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
  //         },
  //       },

  //       contained: {
  //         "&.Mui-disabled": {
  //           backgroundColor: "rgba(0, 0, 0, 0.03) ",
  //         },
  //       },
  //       outlinedPrimary: {
  //         color: "#000",
  //         border: "1px solid #000 !important",
  //         "&:hover": {
  //           color: "#fff",
  //           boxShadow: "none !important",
  //           backgroundColor:
  //             "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
  //           // backgroundColor: "#51ACED !important",
  //           // border: "1px solid #51ACED !important",
  //         },
  //       },
  //     },
  //     MuiPickersCalendarHeader: {
  //       dayLabel: { color: "#000" },
  //     },
  //     MuiPickersClockNumber: { clockNumber: { color: "#000" } },
  //     MuiPickersDay: {
  //       day: {
  //         color: "#000",
  //       },
  //     },
  //     MuiPaginationItem: {
  //       root: {
  //         color: "#000",
  //       },
  //     },
  //     MuiPaginationItem: {
  //       root: {
  //         color: "#000",
  //       },
  //     },
  //     MuiPaper: {
  //       root: { color: "#000" },
  //       elevation2: {
  //         position: "relative",
  //         zIndex: "999",
  //         overflow: "hidden",
  //         padding: "40px",
  //         boxShadow: "-8px -8px 13px 0px #ffffff, 6px 13px 20px #d2d1db",
  //         borderRadius: "20px !important",
  //         backgroundColor: "#F5F5F5",
  //         "@media(max-width:767px)": {
  //           padding: "20px !important",
  //         },
  //       },
  //     },
  //     MuiIconButton: {
  //       root: {
  //         color: "#000000",
  //       },
  //     },

  //     MuiOutlinedInput: {
  //       inputMultiline: {
  //         padding: "1px !important",
  //       },
  //       root: {
  //         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //           border: "none",
  //           boxShadow: "none",
  //         },
  //       },
  //       notchedOutline: {
  //         background: "rgba(0, 0, 0, 0.07)",
  //         borderColor: "rgb(230 226 230)",
  //       },
  //       input: {
  //         borderRadius: "10px",
  //         color: "#000",
  //         padding: "16px 14px",
  //         "&:-webkit-autofill": {
  //           "-webkit-background-clip": "text !important",
  //           // transitionDelay: "9999s",
  //           "caret-color": "transparent",
  //           "-webkit-box-shadow": "0 0 0 100px transparent inset",
  //           "-webkit-text-fill-color": "#000",
  //         },
  //         "&:-internal-autofill-selected": {
  //           color: "#fff",
  //         },
  //       },
  //     },
  //   },
  //   typography: {
  //     fontFamily: "'Saira', sans-serif",
  //   },
  //   palette: {
  //     background: {
  //       card: "linear-gradient(152.97deg, rgb(168 164 164 / 10%) 0%, rgb(66 64 64 / 19%) 100%)",
  //       tab: "#fff",
  //       header: "#fff",
  //       default: "#F5F5F5",
  //       price: "#fef7fa",
  //       pricename: "#fef7fa",
  //       blackCard: "#fef7fa",
  //       tablehead: "#fef7fa",
  //     },
  //     primary: {
  //       main: "#000000", //black
  //     },
  //     secondary: {
  //       main: "#000000", //black
  //       icons: "#009900", //white
  //     },
  //     text: {
  //       primary: "#000", //black
  //       secondary: "#000", //white
  //       gray: "#000",
  //       graydark: "#000",
  //       white: "#000",
  //     },
  //   },
  // },
  {
    name: "DARK",
    overrides: {
      MuiMenu: {
        list: {
          outline: "0",
          background: "rgba(255, 255, 255, 0.05)",
          boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
          borderRadius: "8px",
          "&::before": {
            position: "absolute",
            backdropFilter: "blur(75px)",
            content: "'' ",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            zIndex: "-1",
          },
        },
      },

      MuiDialog: {
        paper: {
          padding: "20px 30px",
          background: "rgba(255, 255, 255, 0.06)",
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          "&::before": {
            position: "absolute",
            backdropFilter: "blur(75px)",
            content: "'' ",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            zIndex: "-1",
          },
          "@media(max-width:767px)": {
            padding: "10px",
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: "#3563F6 !important",
        },
        track: {
          backgroundColor: "#E5E5E5",
          opacity: "1",
        },
      },
      MuiPickersDay: {
        day: {
          color: "#fff",
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: { color: "#fff" },
      },
      MuiTableHead: {
        root: {
          background: "rgba(255, 255, 255, 0.03)",
          boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.03)",
          borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          "&::before": {
            position: "absolute",
            backdropFilter: "blur(10px)",
            content: "'' ",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            zIndex: "-1",
          },
          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
      MuiTableBody: {
        root: {
          background: "rgba(255, 255, 255, 0.03)",
          boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.03)",
          "&::before": {
            position: "absolute",
            backdropFilter: "blur(10px)",
            content: "'' ",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            zIndex: "-1",
          },
        },
      },
      MuiTableRow: {
        root: {
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          "&:hover": {
            backgroundColor: "#ffffff14",
          },
          "&:last-child": {
            borderBottom: "none",
          },
        },
      },
      MuiTableCell: {
        head: {
          padding: "8px 16px",
          fontWeight: "400",
          whiteSpace: 'pre'
        },
        body: {
          whiteSpace: 'pre'
        },
      },
      MuiInputBase: {
        root: {
          // color: "rgba(118, 151, 255, 0.25)",
          color: "#fff",
          cursor: "text",
          display: "inline-flex",
          position: "relative",
          fontSize: "14px",
          boxSizing: "border-box",
          alignItems: "center",
          fontFamily: "'Lexend', sans-serif",

          fontWeight: "300",
          lineHeight: "1.1876em",
          borderRadius: "50px",
          height: "50px",
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.6)",
          },
        },
      },
      MuiSelect: {
        icon: {
          color: "#fff",
        },
      },

      MuiButton: {
        containedPrimary: {
          background: "linear-gradient(94.88deg, #4949F5 0%, #244BD6 81.78%)",
          boxShadow:
            "inset -4px -4px 4px rgba(0, 0, 0, 0.1), inset 4px 4px 4px rgba(255, 255, 255, 0.1)",
          borderRadius: "8px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          marginRight: "10px",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #ad5165, 0 -1px 0 0 #7e46a1, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
            backgroundColor: "transparent",
          },
        },
        containedSecondary: {
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          marginRight: "10px",

          background: "transparent",
          boxShadow:
            "0 1px 0 0 #ad5165, 0 -1px 0 0 #7e46a1, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#fff",
            background:
              "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.025) ",
            color: "#ffffff45",
          },
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#ffffff",
        },
      },
      MuiPaper: {
        root: {
          color: "#fff",
          backgroundColor: "rgba(255, 255, 255, 0.03)",
        },
        elevation2: {
          padding: "40px",
          zIndex: "999",
          overflow: "hidden",
          position: "relative",
          background: "rgba(255, 255, 255, 0.04)",
          boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.03)",
          borderRadius: "0px !important",
          "&::before": {
            position: "absolute",
            backdropFilter: "blur(10px)",
            content: "'' ",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            zIndex: "-1",
          },
          "@media(max-width:767px)": {
            padding: "20px !important",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#fff",
        },
      },
      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "none",
          },
        },
        notchedOutline: {
          background: "rgba(255, 255, 255, 0.06)",
          borderColor: "rgba(255, 255, 255, 0.025)",
        },
        input: {
          borderRadius: "10px",
          color: "#fff",
          padding: "16px 14px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#fff",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      background: {
        card: "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        tab: "rgba(255, 255, 255, 0.07)",
        header: "#2A2A2B",
        default:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        price: "#2B2B2B",
        pricename: "#474747",
        blackCard: "#1E1E1E",
        tablehead: "#1B1A1A",
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#D9D9D9", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#FFFFFF", //white
        secondary: "rgba(255, 255, 255, 0.6)", //white
        gray: " #A9A9A9",
        graydark: "#A7A7A7",
        white: "#fff",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
